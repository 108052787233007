<div class="header-container">
    <div class="left-sdie">
        <img src="./../../../../assets/images/CG_Navbar_Logo.svg">
        <span>CGPay</span>
    </div>
    <div class="right-side">
        @if(cgPayServce.retrunUrlSig()){
        <div class="back-link" (click)="goToLink(cgPayServce.retrunUrlSig())">Go Back to Broker</div>
        }
        <div class="dark-button"><mat-icon (click)="themeService.toggleMode()">dark_mode</mat-icon></div>

    </div>
</div>