import { ModuleWithProviders, NgModule, inject } from '@angular/core';
import { throwIfAlreadyLoaded } from './import.guard';
import { ThemeService } from './services/theme.service';
import { HttpService } from './services/http.service';

@NgModule({
    imports: [
    ],
    exports: [
    ]
})
export class CoreModule {
    constructor() {
        const parentModule = inject(CoreModule, { optional: true, skipSelf: true });

        parentModule && throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                ThemeService,
                HttpService
            ]
        } as ModuleWithProviders<CoreModule>;
    }
}
