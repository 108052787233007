import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { AttentionComponent } from 'src/app/share/components/attention/attention.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  dialog = inject(MatDialog);


  intercept(request: HttpRequest<never>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred';
        this.dialog.open(AttentionComponent);
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          errorMessage = `Client-side error: ${error.error.message}`;
        } else {
          // Server-side error
          errorMessage = `Server-side error (status code ${error.status}): ${error.message}`;
        }

        return throwError(() => errorMessage);
      })
    );
  }
}
