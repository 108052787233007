import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _isLoading = false;
  private _loading$ = new BehaviorSubject<boolean>(this._isLoading);

  setLoading(val: boolean): void {
    this._isLoading = val;
    this._loading$.next(this._isLoading);
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable()
      .pipe(tap((val) => {
        if (val) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
      }));
  }

}
