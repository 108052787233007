import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { LoaderService } from './core/services/loader.service';
import { ThemeService } from './core/services/theme.service';
import { IframeService } from './share/services/iframe.service';

@Component({
  selector: 'app-root',
  template: `
    <div class="content">
      @if(!openInIFrame){
        <cgp-header></cgp-header>
      }
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [`
    .content {
      width: 100%;
      margin: 0 auto;
    }

  `]
})
export class AppComponent implements OnInit {
  themeService = inject(ThemeService);
  loaderService = inject(LoaderService);
  private router = inject(Router);
  private iframeService = inject(IframeService);

  openInIFrame: boolean = false;

  ngOnInit(): void {
    this.init()
  }

  private init() {
    this.themeService.initByPreferred();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo({ behavior: 'smooth', top: 0, left: 0 })
      }
    })

    if (window.self !== window.top) {
      this.openInIFrame = true
      this.iframeService.updateIFrameStatus(true)
      return
    }

    this.openInIFrame = false
    this.iframeService.updateIFrameStatus(false)
  }
}
