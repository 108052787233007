import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetsResolver } from './views/cgpay/service/get-info.resolver';

const routes: Routes = [
  {
    path: '',
    resolve: { info: AssetsResolver },
    loadComponent: () => import('./views/cgpay/cgpay.component').then(com => com.CGPayComponent)
  },
  {
    path: 'time-up',
    loadComponent: () => import('./views/ended-time/ended-time.component').then(com => com.EndedTimeComponent)
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
