import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { Observable, filter, take } from 'rxjs';
import { CGPayService } from './cg-pay.service';
import { AssetsMapperResult } from '../model/client-model/mappet-result-model';
import { AttentionComponent } from 'src/app/share/components/attention/attention.component';
import { MatDialog } from '@angular/material/dialog';

export const AssetsResolver: ResolveFn<unknown> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Observable<unknown> => {

    const cgpayFacade: CGPayService = inject(CGPayService);
    const token = route.queryParamMap.get('token');

    if (!token) {
        const dialog = inject(MatDialog);
        dialog.open(AttentionComponent, { disableClose: true });
        throw new Error("Token Not Valid")
    }

    cgpayFacade.token = token;

    return cgpayFacade.getAssets().pipe(
        filter((assets: AssetsMapperResult) => !!assets)
    );

};
